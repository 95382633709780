<div class="d-flex justify-content-between">
  <div class="d-flex">
  <img class="logo" [src]="data.icon ? data.icon : '../../../assets/placeholder_class.png'"/>
  <h2 class="flex-1 mat-title m-0 ml-2">{{data.title}}</h2>
</div>
  <a mat-button [mat-dialog-close]="true" [href]="data.title === 'Kinderkerk' || data.title === 'Jeugkerk' ? 'https://kruin-kerk.co.za/register' : 'https://docs.google.com/forms/d/e/1FAIpQLSeAs12KY-tHqLCXHB1p9BZ777ciizTCRCyAxkXQh-n00smnsw/viewform?usp=sf_link'" target="_blank" cdkFocusInitial>Word deel</a>
</div>
<mat-dialog-content class="mat-typography pt-3">
  <ng-container *ngIf="data.title === 'Kinderkerk'">
    <img class="img-fluid" src="../../../assets/kk_heading.png">
    <img class="img-fluid" src="../../../assets/kk_intro.png">
    <div class="relative w-100 bg-grey">
    <video #kkvideo width="100%" controls>
      <source src="../../../assets/kk_video.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="play-icon bg-danger" *ngIf="showPlayIcon">
      <fa-icon size="3x" class="text-white" (click)="playVideo()" [icon]="faPlay">play</fa-icon>
    </div>
  </div>


    <img class="img-fluid" src="../../../assets/kk_par.png">
    <a href="https://chat.whatsapp.com/BVorlB9E3kj6RlAhJvXdVR" target="_blank"><img class="img-fluid" src="../../../assets/kk_bytjies_wa.png"></a>
    <a href="https://chat.whatsapp.com/H4S2ayA6K5hK0bvT16VYRl" target="_blank"><img class="img-fluid" src="../../../assets/kk_kleuters_wa.png"></a>
    <a href="https://chat.whatsapp.com/BO5OK1YS0YmBGNS7XM84S6" target="_blank"><img class="img-fluid" src="../../../assets/kk_juniors_wa.png"></a>
    <a href="https://chat.whatsapp.com/Bs3X4XkJBRc9Ah80xmMD9p" target="_blank"><img class="img-fluid" src="../../../assets/kk_seniors_wa.png"></a>
  </ng-container>
  <ng-container *ngIf="data.title === 'Jeugkerk'">
    <img class="img-fluid" src="../../../assets/jeugkerk_banner.jpg">
    <div class="relative w-100 bg-grey">
    <video #kkvideo width="100%" controls>
      <source src="../../../assets/Jeugkerk_Registrasies_2025.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="play-icon bg-danger" *ngIf="showPlayIcon">
      <fa-icon size="3x" class="text-white" (click)="playVideo()" [icon]="faPlay">play</fa-icon>
    </div>
  </div>
  </ng-container>

  <ng-container *ngIf="data.title !== 'Kinderkerk' && data.title !== 'Jeugkerk'">
    <iframe *ngIf="data.video" width="100%" height="450" [src]="data.video | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <div *ngIf="data.qrCode" class="mr-3 d-flex flex-column justify-content-center align-items-center">
      <h3>{{data.title}}</h3>
      <p class="mat-subtitle text-grey">Whatsapp Groep</p>
      <img class="img-fluid" [src]="data.qrCode">
    </div>
  </ng-container>
  <!-- <p>{{data.description}}</p>
  <ng-container *ngIf="data.items">
    <div class="row">
    <div class="col-md-4 pb-3" *ngFor="let item of data.items">
      <div class="p-2 d-flex w-100 align-items-center mat-stroked-button" (click)="openDialog(item)">
        <div class="initials mr-3"><img [src]="item.logo.source ? item.logo.source : '../../../assets/placeholder_class.png'"></div>
        <h3 class="mat-subtitle flex-1 m-0">{{item.name}}</h3>
      </div>
    </div>
  </div>
  </ng-container> -->

</mat-dialog-content>
