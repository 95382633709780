
   <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-12">
        <form
          class="user"
          [formGroup]="profileForm"
          class="d-flex flex-column mt-4"
          (ngSubmit)="saveProfile()"
        >
          <div class="row">
            <div class="col-md-4 d-flex flex-column">
              <mat-form-field>
                <mat-label>Titel</mat-label>
                <mat-select name="title" formControlName="Title">
                  <mat-option
                    *ngFor="let title of titleList"
                    [value]="title"
                    >{{ title }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Naam</mat-label>
                <input
                  matInput
                  placeholder="Naam"
                  name="name"
                  formControlName="Name"
                />
                <mat-error *ngIf="profileForm.controls.Name.invalid"
                  >Name is Required</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Van</mat-label>
                <input matInput placeholder="Van" formControlName="Surname" />
                <mat-error *ngIf="profileForm.controls.Surname.invalid"
                  >Surname is Required</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>ID</mat-label>
                <input
                  matInput
                  placeholder="ID"
                  name="ID"
                  formControlName="ID"
                />
                <mat-error *ngIf="profileForm.controls.ID.invalid"
                  >ID is Required</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Geboorte Datum</mat-label>
                <input
                  readonly
                  matInput
                  [matDatepicker]="DOBPicker"
                  formControlName="Birthday"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="DOBPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #DOBPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-4 d-flex flex-column">
              <mat-form-field>
                <mat-label>Lidmaatstatus</mat-label>
                <mat-select
                  name="MemberStatus"
                  formControlName="MemberStatus"
                >
                  <mat-option
                    *ngFor="let status of memberStatusList"
                    [value]="status"
                  >
                    {{ status }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Vorige kerk</mat-label>
                <input
                  matInput
                  placeholder="Vorige kerk"
                  formControlName="PreviousChurch"
                />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Kontak nommer</mat-label>
                <input
                  matInput
                  placeholder="Kontak nommer"
                  formControlName="Mobile"
                />
                <mat-error *ngIf="profileForm.controls.Mobile.invalid"
                  >Mobile is Required</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Epos</mat-label>
                <input matInput placeholder="Epos" formControlName="Email" />
                <mat-error *ngIf="profileForm.controls.Email.invalid"
                  >Email is Required</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Woonadres</mat-label>
                <input
                  matInput
                  placeholder="Woonadres"
                  formControlName="Address"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4 d-flex flex-column">
              <mat-form-field>
                <mat-label>Huweliks status</mat-label>
                <mat-select
                  name="MaritalStatus"
                  formControlName="MaritalStatus"
                >
                  <mat-option
                    *ngFor="let status of maritalStatusList"
                    [value]="status"
                  >
                    {{ status }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <ng-container
                *ngIf="
                  profileForm.controls['MaritalStatus'].value === 'Getroud'
                "
              >
                <mat-form-field appearance="outline">
                  <mat-label>Troudatum</mat-label>
                  <input
                    readonly
                    matInput
                    [matDatepicker]="MariageDatePicker"
                    formControlName="MariageDate"
                  />
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="MariageDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #MariageDatePicker></mat-datepicker>
                </mat-form-field>
                </ng-container>
                <mat-form-field appearance="outline">
                  <mat-label>Partner ID</mat-label>
                  <input matInput placeholder="Partner ID" name="PartnerID" formControlName="PartnerID">
                  <mat-error *ngIf="profileForm.controls.PartnerID.invalid">Partner ID Required</mat-error>
              </mat-form-field>
                <div class="d-flex">
                <!-- <button *ngIf="profileForm.controls['MaritalStatus'].value === 'Getroud'"
                  color="primary"
                  mat-stroked-button
                  (click)="addSpouse()"
                >
                  <div
                    class="p-3 text-primary d-flex flex-column justify-content-center"
                  >
                    <fa-icon size="2x" [icon]="faUserPlus"></fa-icon>
                    Voeg eggenoot by
                  </div>
                </button> -->

              <!-- <button
                mat-stroked-button
                color="primary"
                (click)="addChild()"
                aria-label="Voeg kind by"
              >
                <div
                  class="p-3 text-primary d-flex flex-column justify-content-center"
                >
                  <fa-icon size="2x" [icon]="faChild"></fa-icon>
                  Voeg kind by
                </div>
              </button> -->
          </div>
          <mat-form-field *ngIf="isAdmin">
            <mat-label>Toegang</mat-label>
            <mat-select
              name="Role"
              formControlName="Role"
              class="capitalize"
            >
              <mat-option
              class="capitalize"
                *ngFor="let role of roles"
                [value]="role"
              >
                {{ role }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="isAdmin">
            <mat-label>Bediening</mat-label>
            <mat-select
              name="Ministry"
              formControlName="Ministry"
              class="capitalize"
            >
              <mat-option
              class="capitalize"
                *ngFor="let ministry of ministries"
                [value]="ministry"
              >
                {{ ministry }}
              </mat-option>
            </mat-select>
          </mat-form-field>
              <ng-container *ngIf="students">
                  <p>Kinders: {{ students.length }}</p>
                  <mat-chip-list>
                      <mat-chip *ngFor="let student of students" style="font-size: 12px">
                          <fa-icon [icon]="faChild"></fa-icon> {{ student.Name }} {{ student.Surname }}</mat-chip>
                    </mat-chip-list>
                </ng-container>
            </div>
          </div>

          <div class="w-100 d-flex justify-content-end mt-4">
            <div class="d-flex"></div>
            <!-- <button [disabled]="loading" mat-raised-button color="primary" class="text-white" (click)="saveProfile()">
              Bevestig profiel
            </button> -->
            <button type="submit" [disabled]="!profileForm.valid || saving" mat-raised-button color="primary">
              {{ profileForm.dirty ? 'Save' : 'Save' }}
            </button>
          </div>
        </form>
    </div>
  </div>
   <!-- <mat-stepper linear #stepper
    [orientation]="(stepperOrientation | async)!">
    <mat-step state="done" *ngIf="profileForm" [completed]="profileForm?.valid" [stepControl]="profileForm" label="Profile">
        <mat-card class="m-3">
            <mat-card-content> -->
                <!-- <form class="user" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
                    <div class="d-flex row p-3">
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Name</mat-label>
                          <input matInput placeholder="Name" name="name" formControlName="Name">
                          <mat-error *ngIf="profileForm.controls.Name.invalid">Name is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Surname</mat-label>
                          <input matInput placeholder="Surname" formControlName="Surname">
                          <mat-error *ngIf="profileForm.controls.Surname.invalid">Surname is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field *ngIf="!profile.ID" appearance="outline">
                          <mat-label>ID</mat-label>
                          <input matInput placeholder="Id" formControlName="ID">
                          <mat-error *ngIf="profileForm.controls.ID.invalid">ID is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Occupation</mat-label>
                          <input matInput placeholder="Occupation" formControlName="Occupation">
                          <mat-error *ngIf="profileForm.controls.Occupation.invalid">Occupation is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Title</mat-label>
                        <input matInput placeholder="Title" formControlName="Title">
                        <mat-error *ngIf="profileForm.controls.Title.invalid">Title is Required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Gender</mat-label>
                        <input matInput placeholder="Gender" formControlName="Gender">
                        <mat-error *ngIf="profileForm.controls.Gender.invalid">Gender is Required</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Address</mat-label>
                          <input matInput placeholder="Address" formControlName="Address">
                          <mat-error *ngIf="profileForm.controls.Occupation.invalid">Address is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Mobile</mat-label>
                          <input matInput placeholder="Mobile" formControlName="Mobile">
                          <mat-error *ngIf="profileForm.controls.Occupation.invalid">Mobile is Required</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Email</mat-label>
                          <input matInput placeholder="Email" formControlName="Email">
                          <mat-error *ngIf="profileForm.controls.Occupation.invalid">Email is Required</mat-error>
                      </mat-form-field>
                      

                  </div>
                </div>
                <mat-toolbar class="d-flex justify-content-end">
                    <button type="submit" [disabled]="!profileForm.valid || saving" mat-raised-button color="primary">
                      {{ profileForm.dirty ? 'Save' : 'Next' }}
                    </button>
                  </mat-toolbar>
                </form> -->
            <!-- </mat-card-content>
          </mat-card>
    </mat-step>
    <mat-step *ngIf="medicalForm" [completed]="medicalForm.valid" [stepControl]="medicalForm" label="Medical Aid">
        <mat-card class="m-3">
            <mat-card-content>
                <form class="user" [formGroup]="medicalForm" (ngSubmit)="saveMedicalDetails()">
                <div class="row d-flex p-3">
                
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Name</mat-label>
                          <input matInput placeholder="MedicalAidName" formControlName="MedicalAidName">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Number</mat-label>
                          <input matInput placeholder="MedicalAidNumber" formControlName="MedicalAidNumber">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Contact Person</mat-label>
                          <input matInput placeholder="MedicalAidMainMember" formControlName="MedicalAidMainMember">
                      </mat-form-field>
                  </div>
                  <div class="col-md-6 d-flex flex-column">
                      <mat-form-field appearance="outline">
                          <mat-label>Problems</mat-label>
                          <input matInput placeholder="HealthProblems" formControlName="HealthProblems">
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                          <mat-label>Notes</mat-label>
                          <input matInput placeholder="Notes" formControlName="Notes">
                      </mat-form-field>
                  </div>
                </div>
                <mat-toolbar class="d-flex justify-content-between">
                    <button type="button" mat-button (click)="back()">
                        Back
                      </button>
                    <button type="submit" [disabled]="!medicalForm.valid || saving" mat-raised-button color="primary">
                        {{ medicalForm.dirty ? 'Save' : 'Next' }}
                    </button>
                  </mat-toolbar>
                </form>
            </mat-card-content>
          </mat-card>
    </mat-step>
    <mat-step *ngIf="profile" [completed]="profile.Document && profile.Document.name" [stepControl]="thirdFormGroup" label="Consent">
        <app-student-consent-form (emitBack)="back()" [student]="profile" (emitStudent)="saveParentConsent($event)"></app-student-consent-form>
    </mat-step>
  </mat-stepper> -->