import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {map} from 'rxjs/operators';
import { ParentService } from 'src/app/admin/services/parent.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from "moment";
import { AuthService } from 'src/app/auth/auth.service';
import { faChild, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { ParentDataService } from 'src/app/parent/services/data.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';
import { Member, MemberConsent } from 'backend/interfaces/member.interface';
import { ParseRoles } from 'backend/enum/ParseRoles.enum';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @Input() profile: Member = {
    Name: '',
    Surname: '',
    ID: '',
    Occupation: '',
    Title: '',
    Gender: '',
    Address: '',
    Mobile: '',
    Email: this.parentService.newParentEmail ? this.parentService.newParentEmail : '',
    Notes: '',
    MedicalAidName: '',
    MedicalAidNumber: '',
    MedicalAidMainMember: '',
    isMainContact: false,
    PartnerID: '',
    Role: '',
    Ministry: ''
  };
  saving = false;
  currentUser;
  isAdmin;
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;
  constructor(private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private parentService: ParentService, private router: Router, private _snackBar: MatSnackBar, private authService: AuthService, private dataService: ParentDataService, private sideNav: SideNavService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'));
      this.authService.$User.subscribe((res)=>{
        this.isAdmin = res?.get('role') === 'admin';
        this.currentUser = res;
      })
  }
  profileForm: UntypedFormGroup;
  medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  roles = [
    ParseRoles.ADMIN,
    ParseRoles.MEMBER,
    ParseRoles.STAFF
  ];
  ministries = [
    'Kinderkerk',
    'Jeugkerk'
  ];

  titleList = [
    'Mnr', 'Me'
  ];
  maritalStatusList = [
    'Getroud',	'Ongetroud',	'Geskei',	'Wewenaar',	'weduwee',
  ];
  memberStatusList = [
    'Nuwe Intrekker','Belydend',	'Doop',	'Ongedoop',
  ]
  faUserPlus = faUserPlus;
  faChild = faChild;
  stepperOrientation: Observable<StepperOrientation>;
  students;
  
  async ngOnInit(): Promise<void> {
    console.log(this.profile)
    if(!this.profile.ID){
    this.parentService.activatedParent$.subscribe(async res => {
      if(res){
      this.profile = res;
      await this.dataService.getStudentsByParentId(this.profile.ID, true).then(res => {
        if(res){
        this.dataService.students.next(res);
        }
      });
      }else{
        this.profile = {
          Name: '',
          Surname: '',
          ID: '',
          Occupation: '',
          Title: '',
          Gender: '',
          Address: '',
          Mobile: '',
          Email: '',
          Notes: '',
          MedicalAidName: '',
          MedicalAidNumber: '',
          MedicalAidMainMember: '',
          isMainContact: false,
          PartnerID: '',
          Role: '',
          Ministry: ''
        };
      }
    });
    this.dataService.students$.subscribe(async res => {
      this.students = res;
    })
  }

    this.profileForm = this.fb.group({
      Name: [this.profile.Name, Validators.required],
      Surname: [this.profile.Surname, Validators.required],
      Mobile: [this.profile.Mobile, Validators.required],
      Email: [this.profile.Email, Validators.required],
      AdditionalNotes: [this.profile.Notes],
      Title: [this.profile.Title, Validators.required],
      Birthday: this.profile.DOB ? new Date(this.profile.DOB) : this.getDateOfBirth(this.profile.ID ? this.profile.ID : ''),
      ID: [this.profile.ID, Validators.required],
      Address: [this.profile.Address],
      PostalAddress: [this.profile.PostalAddress],
      MemberStatus: [this.profile.MemberStatus],
      MaritalStatus:[this.profile.MaritalStatus],
      MariageDate: [this.profile.MariageDate],
      PartnerID: [this.profile.PartnerID],
      PreviousChurch: [''],
      Occupation: [this.profile.Occupation],
      Role: [this.profile.Role],
      Ministry: [this.profile.Ministry]
    });
  }
  async saveProfile(){
    if(this.profileForm.dirty){
      this.saving = true;
      const parentID = this.profileForm.controls['ID'].value;
      let DOB = '';
      const yy = parentID.substring(0,2)
      const mm = parentID.substring(2,4)
      const dd = parentID.substring(4,6)
      if(parentID.length > 10){
        DOB = moment(new Date(mm+'/'+dd+'/'+yy)).format('MMM DD yyyy')
      }
      this.profileForm.controls['PartnerID'].patchValue(this.profileForm.controls['MaritalStatus'].value === 'Getroud' ? this.profileForm.controls['PartnerID'].value : '')
      this.parentService
      .saveParent({...this.profileForm.value, DOB})
      .then((res) => {
        this.parentService.newParentEmail = undefined;
        this.openSnackBar('Sukses!');
        this.sideNav.close();
        // this.stepper.next();
        this.parentService.getParentCount();
        this.saving = false
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
      this.profileForm.markAsPristine();
      this.profile.ID = this.profileForm.controls['ID'].value;
    } else {
      this.stepper.next();
    }
  }
  async saveMedicalDetails(){
    if(this.medicalForm.dirty){
      this.saving = true;
      const medicalForm = {...this.medicalForm.value, 
        ID: this.profile.ID};

        this.parentService
        .saveParentMedicalDetails(medicalForm)
        .then(() => {
          this.openSnackBar('Medical details saved');
          this.saving = false;
          this.stepper.next();
        })
        .catch((err) => {
          this.openSnackBar(err);
          this.saving = false;
        });
      this.medicalForm.markAsPristine();
    } else {
      this.stepper.next();
    }
  }
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }
  
    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveMemberConsent(event: {member: MemberConsent, signature: string}){
    this.saving = true;
    this.parentService
      .saveParentConsent(event.member)
      .then(() => {
        this.openSnackBar('Consent saved');
        this.emitProfile.emit(event.member);
        this.saving = false;
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
  }


  back(){
    this.stepper.previous();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }


  getDateOfBirth(idNumber):Date {
    let birthday;
    const currentYear = new Date().getFullYear();
    const year = idNumber.substring(0, 2);
    const month = idNumber.substring(2, 4);
    const day = idNumber.substring(4, 6);
    var date = new Date(month + "/" + day + "/" + year);
    if(date.getFullYear() > currentYear){
      birthday = new Date(new Date(month + "/" + day + "/" + '19'+year))
    }else{
      birthday = new Date(new Date(month + "/" + day + "/" + year))
    }
    return birthday
  }
}



