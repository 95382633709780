
<div class="d-flex justify-content-between align-items-center p-2 flex-wrap">
  <div>
    <mat-form-field>
      <mat-label>Lokaal</mat-label>
      <mat-select [(ngModel)]="selectedLocation" name="appointmentLocation" (selectionChange)="setLocation($event)">
        <mat-option [value]="'All'">Alle lokale</mat-option>
        <mat-option *ngFor="let location of locations" [value]="location.name">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <button mat-raised-button (click)="sendReminderEmails()">Send Reminders</button> -->
  </div>
    <div class="flex-1 d-flex justify-content-end align-items-center p-2 flex-wrap">
      <div>
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          M
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          W
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          D
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center pl-2 pr-2">
        <h4 class="m-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h4>
    </div>
    <div class="">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
            <fa-icon [icon]="faChevronLeft"></fa-icon>
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
          >
            Today
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
          >
          <fa-icon [icon]="faChevronRight"></fa-icon>
          </div>
        </div>
      </div>
    </div>
</div>

<div *ngIf="!loading">
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="viewAppointment($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [dayStartHour]="03"
      [dayStartMinute]="00"
      [dayEndHour]="23"
      [dayEndMinute]="00"
      (hourSegmentClicked)="createAppointment($event)"
      (eventClicked)="viewAppointment($event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [dayStartHour]="03"
      [dayStartMinute]="00"
      [dayEndHour]="23"
      [dayEndMinute]="00"
      [refresh]="refresh"
      (eventClicked)="viewAppointment($event.event)"
      (hourSegmentClicked)="createAppointment($event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  
  </div>
</div>
