<form [formGroup]="contactForm" (submit)="submit()">
    <div class="d-flex row p-0">
    <div class="col-md-12 d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>Naam</mat-label>
            <input matInput placeholder="name" name="name" formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Kontak nommer</mat-label>
            <input matInput placeholder="Kontak nommer" name="number" formControlName="number">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Epos adres</mat-label>
            <input matInput type="email" placeholder="Epos adres" name="email" formControlName="email">
        </mat-form-field>
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Boodskap</mat-label>
            <textarea
              rows="5"
              matInput
              placeholder="Boodskap"
              formControlName="message"
            ></textarea>
          </mat-form-field>
    </div>
    </div>
    <div mat-toolbar class="d-flex justify-content-between p-3">
        <div class="spacer"></div>
        <button mat-raised-button color="accent" [disabled]="loading || contactForm.invalid" type="submit" class="btn-block btn btn-outline-secondary">
            {{ loading ? '...' : 'Stuur' }}
        </button>
    </div>
</form>
