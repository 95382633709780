<mat-toolbar>
    <button mat-icon-button (click)="print()">
        <mat-icon>print</mat-icon>
    </button>
</mat-toolbar>
<div class="d-flex flex-wrap mb-3 justify-content-center sticker mt-3" id="section-to-print">
    <div class="ml-2 d-flex w-100 justify-content-between align-items-center relative">
        <div class="flex-1">
            <h3 style="font-size: 28px; margin: 0px" class="m-0">{{data.student.Name}} {{data.student.Surname}}</h3>
            <div class="d-flex align-items-center" m-0>
                <span style="font-size: 16px;" *ngIf="data.student.DOB" class="m-0 mr-3 w-100">
                    <img style="max-width: 16px" class="mr-2" src="./../../../assets/cake-candles-solid.svg"/> {{data.student.DOB}}</span>
            </div>
            <div class="d-flex align-items-center m-0">
                <span style="font-size: 16px;" *ngIf="data.student.Group" class="m-0 w-100">
                    <img style="max-width: 16px" class="mr-2" src="./../../../assets/book-open-reader-solid.svg"/> {{data.student.Group.name}}</span>
            </div>
            <h3 style="font-size: 20px;margin: 0px" *ngIf="data.student.Grade" class="m-0">{{data.student.Grade}}</h3>
            <div style="margin: 10px 0px;">
            <h3 style="font-size: 20px; margin: 0px" *ngIf="parent1" class="m-0">{{parent1.Name}}: {{parent1.Mobile}}</h3>
            <h3 style="font-size: 20px; margin: 0px" *ngIf="parent2 && (parent1?.Name !== parent2.Name)" class="m-0">{{parent2.Name}}: {{parent2.Mobile}}</h3>
            <h3 style="font-size: 20px; margin: 0px" *ngIf="(data.student.ParentName && data.student.ParentContact) && (!parent1 && parent2)" class="m-0">{{data.student.ParentName}}: {{data.student.ParentContact}}</h3>
            </div>
            <mat-spinner *ngIf="(data.student.ParentID1 && !parent1 && !data.student.ParentContact)" style="margin: auto" color="primary" diameter="20"></mat-spinner>
        </div>
        <img *ngIf="data.student.SpecialNeeds" style="position: absolute; bottom: 40px; right: 20px;" width="40px" src="./../../../assets/kinderhulp.png">
        <img style="position: absolute; top: 60px; right: 20px;" width="60px" src="./../../../assets/icon_kinders copy.png">
    </div>
  </div>