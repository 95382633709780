
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

  <mat-card class="m-3 scrollable">
    <mat-card-content>
        <form class="user" [formGroup]="serviceForm" (ngSubmit)="saveService()">
          <div class="row m-0 mb-3">
            <div style="max-width: 300px;" class="col-md-5 photo-container border-radius-5 link" (click)="uploadFile()">
              <fa-icon class="upload-icon text-secondary" [icon]="faUpload" size="2x"></fa-icon>
              <img class="text-center margin-auto link img-fluid" [src]="coverImage" #imagePreview alt="image">
              <input
              type="file"
              (change)="onFileSelected($event)"
              #fileInput
              formControlName="coverImage"
              style="display: none;"
            />
            </div>          
            <div class="col-md-7 d-flex align-items-center">
              <mat-form-field class="flex-1 w-100 mat-display-1">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title" placeholder="title" />
            </mat-form-field>
            </div>
  
        </div>
        
          <div class="d-flex flex-column">
              <mat-form-field appearance="outline">
                  <mat-label>Titel</mat-label>
                  <input matInput placeholder="title" formControlName="title">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Beskrywing</mat-label>
                  <input matInput placeholder="description" formControlName="description">
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Tydsduur</mat-label>
                  <input matInput placeholder="duration" formControlName="duration">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Prys/Koste</mat-label>
                <input matInput placeholder="price" formControlName="price">
            </mat-form-field>
          </div>
          <div *ngIf="service.driveId">
            <h3 *ngIf="files?.length < 1 && !service.Document" class="m-3 text-muted">Geen Dokumente</h3>
            <div class="d-flex justify-content-center" *ngIf="files?.length > 0">
                <!-- <p>{{studentId}}</p> -->
                <div *ngIf="files && files.length > 0" class="flex-1 align-items-center scrollable border-primary p-2 mr-2">
                <div *ngFor="let file of files" class="mb-3 link bg-primary p-2 fileList border-radius-5 text-white text-center" (click)="getFile(file.id, file.mimeType, file.name)">
                            <fa-icon class="text-white mr-3" [icon]="faFile"></fa-icon> {{file.name}}
                  </div>
                </div>
            </div>
    
      </div>
    <button type="button" mat-stroked-button color="accent" (click)="createDriveFolder()" *ngIf="!service.driveId">Skep stoorplek</button>
        <div class="m-3" *ngIf="service?.driveId" mat-dialog-actions>
            <input
            type="file"
            class="file-input"
            (change)="onFileSelectedforDriveUpload($event)"
            #fileInput
    
          /><button type="button" [disabled]="!uploadedFile?.source" mat-stroked-button color="primary" (click)="uploadFileToDrive()">Laai dokument</button>
        </div>
        <mat-toolbar class="d-flex justify-content-between">
            <button mat-flat-button color="error" aria-label="Delete" *ngIf="service" type="button" (click)="deleteService()">
              <fa-icon [icon]="faTrash"></fa-icon> 
            </button>
            <button type="submit" [disabled]="!serviceForm.valid || saving" mat-raised-button color="primary">
                Stoor
            </button>
          </mat-toolbar>
        </form>
    </mat-card-content>
  </mat-card>