import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { Service, ServiceSearchCriteria } from 'backend/interfaces/service.interface';
@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  services = new BehaviorSubject<Service[] | null>(null);
  services$ = this.services.asObservable();
  serviceCount = new BehaviorSubject<number | null>(null);
  serviceCount$ = this.serviceCount.asObservable();
  openService: Service;
  constructor() {}

  saveService(service: Service): Promise<Service> {
    return Parse.Cloud.run('saveService', { service }).then((result) => {
      return result;
    });
  }

  getServices(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getServices', { displayLimit, page }).then((result) => {
      console.log(result)
      this.services.next(result);
    });
  }

  getServiceByName(name: string): Promise<Location> {
    return Parse.Cloud.run('getServiceByName', { name });
  }

  getServiceById(id: string): Promise<Service> {
    return Parse.Cloud.run('getServiceById', { id });
  }

  getServicesCount(): Promise<number>{
    return Parse.Cloud.run('getServiceCount').then((result) => {
      this.serviceCount.next(result);
    });;
  }

  deleteService(id: string): Promise<any>{
    return Parse.Cloud.run('deleteService', { id }).then((result) => {
      return result;
    });;
  }

  searchService(criteria: ServiceSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchService', { criteria });
  }

    createDriveFolder(serviceId: string): Promise<any>{
      return Parse.Cloud.run('createServiceDriveFolder', { serviceId })
    }

      uploadFile(id: string, uploadedFile: any, description: string): Promise<any>{
        return Parse.Cloud.run('uploadFile', { id, uploadedFile, description })
      }

        getServiceFiles(fileId: string): Promise<any>{
          return Parse.Cloud.run('getFilesById', { fileId })
        }

}