import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from 'src/app/admin/services/location.service';
import { Location } from 'backend/interfaces/location.interface';
import { Service } from 'backend/interfaces/service.interface';
import { ServicesService } from 'src/app/admin/services/services.service';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {
  saving = false;
  faTrash = faTrash;
  faUpload = faUpload;
  files;
  coverImage = './../../../assets/placeholder_food.png';
  @Input() service: Service = {title: '', description: '', duration: '', note: '', id: '', price: ''};
  @Output() emitService: EventEmitter<any> = new EventEmitter();
  @Output() closeService: EventEmitter<any> = new EventEmitter();
  serviceForm: UntypedFormGroup;
  uploadedImage: {name: string, source: string | ArrayBuffer};
  
  @ViewChild('fileInput') fileInput: ElementRef;

  @ViewChild('imagePreview', { read: ElementRef }) imagePreview: ElementRef;

  selectedFile: {type: string, src: string};
  uploadedFile = {name: '', type: '', source: null};
  
  constructor(private fb: UntypedFormBuilder, private servicesService: ServicesService, private _snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {

    this.serviceForm = this.fb.group({
      title: [this.service ? this.service.title : '', Validators.required],
      description: [this.service ? this.service.description : '', Validators.required],
      duration: [this.service ? this.service.duration : ''],
      note: [this.service ? this.service.note : ''],
      id: [this.service ? this.service.id : ''],
      price: [this.service ? this.service.price : ''],
      coverImage: this.service ? this.service.coverImage : {name: '', source: ''}
    })

    if(this.service.coverImage.source){
      this.coverImage = this.service.coverImage.source;
    }

    if(this.service?.driveId){
      this.servicesService.getServiceFiles(this.service?.driveId).then(async res => {
          if(res.includes("not found"))
          {
            this.files = []
          }else{
            this.files = res;
          }
      });
    }
  }

  async saveService(){
    this.saving = true;
    let updatedService;
    if(this.service.id){
      updatedService = {...this.serviceForm.value, id: this.service.id};
    }else{
      updatedService = this.serviceForm.value;
    }
    if(this.uploadedImage?.name){
      updatedService.coverImage = this.uploadedImage
    }
    this.fileInput.nativeElement.value = null;
    this.serviceForm.controls['coverImage'].setValue('');
    this.servicesService.saveService(updatedService).then(res => {
      if(res){
        this.coverImage = res.coverImage.source;
      }
      this.openSnackBar('Service saved');
      this.servicesService.getServices(10, 0);
      this.servicesService.getServicesCount();
      this.router.navigate(['admin', 'service', res.id])
      this.saving = false;
    }).catch((err) => {
      this.openSnackBar(err);
      this.saving = false;
    });


    // if(this.serviceForm.dirty || this.uploadedImage){
    //   this.saving = true;
    //   this.servicesService
    //   .saveService(this.serviceForm.value)
    //   .then((res) => {
    //     this.saving = false;
    //     this.openSnackBar('Service saved');
    //     this.servicesService.getServices(10, 0);
    //     this.servicesService.getServicesCount();
    //     this.closeService.emit();
    //   })
    //   .catch((err) => {
    //     this.openSnackBar(err);
    //     this.saving = false;
    //   });
    //   this.serviceForm.markAsPristine();
    //   this.service.title = this.serviceForm.controls['title'].value;
    // }else{
    //   this.closeService.emit();
    // }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  deleteService(){
    this.saving = true;
    this.servicesService.deleteService(this.service.id).then(res => {
      this.saving = false;
      this.openSnackBar('Service deleted');
      this.servicesService.getServices(10, 0);
      this.servicesService.getServicesCount();
      this.closeService.emit();
    }).catch(err => {
      this.openSnackBar(err);
    })
  }

  uploadFile(){
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  async uploadFileToDrive(){
    let description = '';
    this.saving = true;
    this.servicesService.uploadFile(this.service.driveId, this.uploadedFile, description).then(async res => {
      this.openSnackBar('Upload Successful!');
      await this.servicesService.getServiceFiles(this.service.driveId).then(res => {
        if(res.includes("not found"))
        {
          console.log(res)
        }else{
          this.files = res;
        }
      })
      this.saving = false;
    });
  }

  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.type.includes('image')) {
          this.imagePreview.nativeElement.src = reader.result;
          this.uploadedImage = {
            source: reader.result as any,
            name: file.name
          }
        }
      };
    }
  }

  async onFileSelectedforDriveUpload(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
          reader.onload = () => {
              this.uploadedFile = {
                source: reader.result,
                type: file.type,
                name: file.name
              }
          };
          reader.readAsDataURL(file)
    }
  }

  createDriveFolder(){
    this.saving = true;
    this.servicesService.createDriveFolder(this.service.id).then(async result => {
      this.service.driveId = result.get('driveId');
      // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      // this.router.navigate(['admin', 'services', {id: this.service.id}]));
      this.saving = false;
    });
  }
}
