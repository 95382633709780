<div class="m-0 row justify-content-center bg-black h-100">
  <div class="col-md-6 bg-image-1 d-flex flex-column justify-content-center align-items-center">
    <router-outlet></router-outlet>
    <div class="d-flex justify-content-around">
      <div *ngFor="let group of groups" (click)="openDialog(group)" class="link">
          <img width="150" class="img-fluid icon" [src]="group.icon">
      </div>
      </div>
    <!-- <button class="mb-2 btn-block" mat-stroked-button color="primary" (click)="explore()" type="button">Explore</button> -->
</div>
  <div class="desktop col-md-6 p-3 text-center flex-column align-items-center justify-content-center ">
    <img class="img-fluid relative" src="assets/icon.png" />
    <button mat-raised-button color="accent" class="text-white" routerLink="../home">Tuisblad</button>
    </div>
</div>
