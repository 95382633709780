import { Injectable } from '@angular/core';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { Service } from 'backend/interfaces/service.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { Group } from 'backend/interfaces/group.interface';
import { Member } from 'backend/interfaces/member.interface';
import { Ministry } from 'backend/interfaces/ministry.interface';
import * as cheerio from 'cheerio';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  services = new BehaviorSubject<Service[] | null>(null);
  services$ = this.services.asObservable();
  locations = new BehaviorSubject<Location[] | null>(null);
  locations$ = this.locations.asObservable();
  albums = new BehaviorSubject<any[] | null>(null);
  albums$ = this.albums.asObservable();
  memberConsent = new BehaviorSubject<any[] | null>(null);
  memberConsent$ = this.memberConsent.asObservable();
  practitioners = new BehaviorSubject<Member[] | null>(null);
  practitioners$ = this.practitioners.asObservable();
  practitionerCount = new BehaviorSubject<number | null>(null);
  practitionerCount$ = this.practitionerCount.asObservable();
  groups = new BehaviorSubject<Group[] | null>(null);
  groups$ = this.groups.asObservable();
  parent = new BehaviorSubject<Member | null>(null);
  parent$ = this.parent.asObservable();
  ministries = new BehaviorSubject<Ministry[] | null>(null);
  ministries$ = this.ministries.asObservable();
  verseOfTheDay = new BehaviorSubject<any | null>(null);
  verseOfTheDay$ = this.verseOfTheDay.asObservable();
  constructor() {
    // this.getServices();
    // this.getLocations();
  }

  getServices(displayLimit?: number, page?: number): Promise<void> {
    displayLimit = 50;
    page = 0;
    return Parse.Cloud.run('getServices', { displayLimit, page }).then((result) => {
      this.services.next(result);
    });
  }

  getVerseOfTheDay(){
    return Parse.Cloud.run('getBibleVerseOfTheDay', {}).then(async res => {
      const $ = cheerio.load(res);
      const versesArray: Array<String> = [];
      const citationsArray: Array<String> = [];
      let version;
      const imageArray: Array<String> = [];
        // Nextjs way :)
        const nextWay = $("script#__NEXT_DATA__").eq(0);
        if (nextWay != null) {
            let json = JSON.parse(nextWay.html() || "");
            const verse = json.props.pageProps.verses[0].content.replace(/\n/g, ' ');
            const reference = json.props.pageProps.verses[0].reference.human;
            const version = json.props.pageProps.versionData.abbreviation;

            const images = $("a.block");
            await images.each((i, p) => {
                let image = `https://www.bible.com${$(p).find('img').attr()?.src}`
                imageArray.push(image);
            })

            this.verseOfTheDay.next({
                citation: `${reference}`,
                passage: verse,
                images: imageArray ?? [],
                version: version
            })
        }
        // Old way :(
        else {
            const versesArray: Array<String> = [];
            const citationsArray: Array<String> = [];
            let version;

            const verses = $("a.text-text-light.w-full.no-underline");
            const citations = $("p.text-gray-25");
            const images = $("a.block");

            await citations.each((i, p) => {
                let citation = $(p).eq(0).text();

                // cut the ending (ESV), (NIV), etc and store it in version
                version = citation.slice(-4).replace(/[()]/g, '');

                // cut the version from the citation
                citation = citation.slice(0, -6);

                citationsArray.push(citation)
            })

            await verses.each((i, p) => {
                let unformattedVerse = $(p).eq(0).text();
                let formattedVerse = unformattedVerse.replace(/\n/g, ' ');
                versesArray.push(formattedVerse)
            })

            await images.each((i, p) => {
                let image = `https://www.bible.com${$(p).find('img').attr()?.src}`
                imageArray.push(image);
            })

            this.verseOfTheDay.next({
                citation: citationsArray[0],
                passage: versesArray[0],
                image: imageArray ?? [],
                version: version
            })
        }
    });
  }

  getGroups(displayLimit?: number, page?: number): Promise<void> {
    displayLimit = 50;
    page = 0;
    return Parse.Cloud.run('getGroups', { displayLimit, page }).then((result) => {
      this.groups.next(result);
    });
  }

  getLocations(): Promise<Location[]> {
    return Parse.Cloud.run('getLocations').then((result) => {
      this.locations.next(result);
    });
  }

  getPractitioners(ministry: string): Promise<Practitioner[]> {
    const page = 0;
    const displayLimit = 10;
    const role = 'staff'
    return Parse.Cloud.run('getParents', {displayLimit, page, role, ministry}).then((result) => {
      this.practitioners.next(result);
    });
  }

  getPractitionerCount(): Promise<number>{
    return Parse.Cloud.run('getPractitionerCount').then((result) => {
      this.practitionerCount.next(result);
    });;
  }

  getCalendarBusy(from: string, to: string): Promise<any>{
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getAvailableTimeSlots(day: number, month: number, year: number, duration: number): Promise<any>{
    return Parse.Cloud.run('getAvailableTimeSlots', {day, month, year, duration})
  }

  getAvailableTimes(duration: number, day: number, month: number, year: number, location: string, preferredPractitioner: string): Promise<any>{
    return Parse.Cloud.run('getAvailableTimes', {duration, day, month, year, location, preferredPractitioner})
  }

  getAlbums(id: string): Promise<any[]> {
    return Parse.Cloud.run('getAlbumList', { id }).then((result) => {
      this.albums.next(result);
    });
  }

  getMemberConsent(id: string): Promise<any[]> {
    return Parse.Cloud.run('getMemberConsent', { id }).then((res) => {
      this.memberConsent.next(res.get('memberConsent'));
    });
  }
  getMinistries(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getMinistries', { displayLimit, page }).then((result) => {
      this.ministries.next(result);
    });
  }

  getFileByIdForDownload(id: string, mimeType: string): Promise<any>{
    return Parse.Cloud.run('getFileByIdForDownload', { id, mimeType })
  }

  contactUsEmail(form: any): Promise<any>{
    return Parse.Cloud.run('contactUsEmail', { form });
  }

}
