import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Group } from 'backend/interfaces/group.interface';
import { Member } from 'backend/interfaces/member.interface';
import { Student } from 'backend/interfaces/student.interface';
import { ParentService } from 'src/app/admin/services/parent.service';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { StudentService } from 'src/app/admin/services/student.service';
import * as Parse from 'parse';
import { faBirthdayCake, faBookOpen } from '@fortawesome/free-solid-svg-icons';
interface DialogData {
  student: Student;
}

@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.scss']
})


export class StudentCardComponent implements OnInit {
  parent1: Member;
  loading = false;
  parent2: Member;
  faBirthdayCake = faBirthdayCake;
  faBookOpen = faBookOpen;
  constructor(public dialogRef: MatDialogRef<StudentCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private studentService: StudentService, private practitionerService: PractitionerService, private parentService: ParentService, private _snackBar: MatSnackBar) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    if(this.data.student.ParentID1){
    const id = this.data.student.ParentID1;
    this.parentService.getParentById(id).then(async res => {
        this.parent1= await res;
    });
    }
    if(this.data.student.ParentID2){
      const id = this.data.student.ParentID2;
      this.parentService.getParentById(id).then(async res => {
          this.parent2 = await res;
      });
      }
  }

  print(){
    // window.print();

    const mywindow = window.open('', 'PRINT', 'height=600,width=600');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write(document.getElementById('section-to-print').innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    // mywindow.close();

    return true;
  }

}
