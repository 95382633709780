<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div class="m-3">
      <form class="user mt-3 w-100" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
        <div class="d-flex flex-column">
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Ouer 1 ID</mat-label>
                <input
                  matInput
                  placeholder="Ouer 1 ID nommer"
                  name="ParentID1"
                  formControlName="ParentID1"
                />
                <mat-error *ngIf="profileForm.controls.ParentID1.invalid"
                  >Ouer 1 ID word vereis</mat-error
                >
                <button mat-raised-button color="primary" *ngIf="profileForm.controls.ParentID1.value" mat-icon-button matSuffix (click)="viewParent(profileForm.controls.ParentID1.value)">
                    <fa-icon class="text-white" [icon]="faUser"></fa-icon>
                  </button>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Ouer 2 ID</mat-label>
                <input
                  matInput
                  placeholder="Ouer 2 ID Number"
                  name="ParentID2"
                  formControlName="ParentID2"
                />
                <mat-error *ngIf="profileForm.controls.ParentID2.invalid"
                  >Ouer 2 ID word vereis</mat-error
                >
                <button mat-raised-button color="primary" *ngIf="profileForm.controls.ParentID2.value" mat-icon-button matSuffix (click)="viewParent(profileForm.controls.ParentID2.value)">
                    <fa-icon class="text-white" [icon]="faUser"></fa-icon>
                  </button>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Ouer Naam en van</mat-label>
            <input matInput placeholder="Ouer Naam en van" name="ParentName" formControlName="ParentName" />
            <mat-error *ngIf="profileForm.controls.ParentName.invalid"
              >Ouer Naam word vereis</mat-error
            >
          </mat-form-field>          
          <mat-form-field appearance="outline">
            <mat-label>Ouer Kontak nommer</mat-label>
            <input matInput placeholder="Ouer Kontak nommer" name="ParentContact" formControlName="ParentContact" />
            <mat-error *ngIf="profileForm.controls.ParentContact.invalid"
              >Ouer kontak nommer word vereis</mat-error
            >
          </mat-form-field>          
          <p>Leerder besonderhede</p>
          <mat-form-field appearance="outline">
            <mat-label>Naam</mat-label>
            <input matInput placeholder="Kind Naam" name="name" formControlName="Name" />
            <mat-error *ngIf="profileForm.controls.Name.invalid"
              >Naam word vereis</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Van</mat-label>
            <input matInput placeholder="Surname" formControlName="Surname" />
            <mat-error *ngIf="profileForm.controls.Surname.invalid"
              >Van word vereis</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>ID</mat-label>
            <input matInput placeholder="Id" formControlName="ID" />
            <mat-error *ngIf="profileForm.controls.ID.invalid"
              >ID word vereis</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Geboorte Datum</mat-label>
            <input
              readonly
              matInput
              [matDatepicker]="picker1"
              formControlName="DOB"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Geslag</mat-label>
            <mat-select name="Geslag" formControlName="Gender">
              <mat-option
                value="Manlik"
                >Manlik</mat-option
              >
              <mat-option
                value="Vroulik"
                >Vroulik</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Notas</mat-label>
            <input matInput placeholder="Notes" formControlName="Notes" />
          </mat-form-field>
          <div class="d-flex flex-wrap align-items-center">
            <mat-form-field appearance="fill" class="flex-1">
              <mat-label>Graad</mat-label>
              <mat-select (selectionChange)="setGroup($event)" formControlName="Grade" name="Grade">
                <mat-option *ngFor="let grade of subgroups" [value]="grade">{{grade}}</mat-option>
              </mat-select>
            </mat-form-field>
            <p *ngIf="profileForm">{{profileForm.controls['Group'].value ? profileForm.controls['Group'].value.name : ''}}</p>
            <!-- <mat-form-field appearance="fill">
              <mat-label>{{ studentClass ? studentClass.name : "" }} Groep</mat-label>
              <mat-select formControlName="Group" name="Group">
                <mat-option *ngFor="let group of groups" [value]="group">{{
                  group.name
                }}</mat-option>
              </mat-select>
            </mat-form-field> -->
          </div>
          <div class="d-flex flex-column ">
            <mat-slide-toggle class="mb-3" formControlName="RegistrationPaid">Registrasie Betaal</mat-slide-toggle>
            <mat-slide-toggle class="mb-3" formControlName="SpecialNeeds">Addisionele aandag</mat-slide-toggle>
          </div>
        </div>
        <mat-toolbar class="d-flex justify-content-end">
          <button
            type="submit"
            [disabled]="!profileForm.valid || loading"
            mat-raised-button
            color="primary"
          >
            Save
          </button>
        </mat-toolbar>
      </form>
      </div>