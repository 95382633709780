import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAuthGuardService as AdminCanActivate } from './auth/admin-auth-guard.service';
import { UserAuthGuardService as UserCanActivate } from './auth/user-auth-guard.service';
import { NewAppointmentComponent } from './new-appointment/new-appointment.component';
import { PdfWorkerComponent } from './pdf-worker/pdf-worker.component';
import { OnboardingComponent } from './shared/onboarding/onboarding.component';
import { NewOrderComponentComponent } from './new-order/new-order-component';
const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./public-site/public-site.module').then(m => m.PublicSiteModule)
  },
  {
    path: "auth",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: "admin",
    canActivate:[AdminCanActivate],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: "member",
    canActivate:[UserCanActivate],
    loadChildren: () => import('./parent/parent.module').then(m => m.ParentModule)
  },
  {
    path: "user",
    canActivate:[UserCanActivate],
    component: OnboardingComponent
  },
  {
    path: "bestellings",
    component: NewOrderComponentComponent
  },
  {
    path: "pdf-worker",
    component: PdfWorkerComponent
  },
  {
    path: "explore",
    loadChildren: () => import('./public-site/public-site.module').then(m => m.PublicSiteModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
