import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faBook, faCalendar, faChurch, faPlay, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DataService } from 'src/app/services/data.service';
import { ReadMoreDialogComponent } from '../read-more-dialog/read-more-dialog.component';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {
  faYoutube = faPlay;
  faSoundcloud = faVolumeUp;
  eredienste = [
    {
      title: 'Koffie Verkope',
      time: '8:30'
    },
    {
      title: 'Oggenddiens',
      time: '9:00'
    },
    {
      title: 'Kinderkerk',
      time: '9:00'
    },
    {
      title: 'Jeug Saamkuier',
      time: '16:45'
    },
    {
      title: 'Aanddiens',
      time: '17:15'
    },
    {
      title: 'Jeugkerk',
      time: '17:15'
    }
  ]
  gebeure = [];
  faBook = faBook;
  faChurch = faChurch;
  faCalendar = faCalendar;
  votd;
  groups = [
    {
      title: 'Kinderkerk',
      description: '',
      url: 'https://drive.google.com/file/d/1EnE0mjhb19x2YvLWt0r6rf2Oig7Yo3gc/preview',
      icon: '../../../assets/icon_kinders.png',
      video: 'https://drive.google.com/file/d/15WXWO_MK0CrQi10ufrkESz0ceuYEdYBA/preview'
    },{
      title: 'Jeugkerk',
      description: '',
      url: '',
      icon: '../../../assets/icon_jeug.png',
      video: 'https://www.youtube.com/embed/FLZ7b8dIDbg?si=oPrTNJmebplVVU5U'
    }];
  constructor(private router: Router, private dataService: DataService, private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.dataService.verseOfTheDay$.subscribe(res => {
      this.votd = res;
    })

    // this.appointmentService.appointments$.subscribe(res => {
    //   console.log(res)
    //   this.gebeure = res;
    // })
  }

  viewCalendarGallery(){
    this.router.navigate(['.', 'kalender']);
  }

  readonly dialog = inject(MatDialog);

  openDialog(group: any) {

    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: group,
      width: '800px',
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
