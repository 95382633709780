

<div class="container">
      <div class="row">
    <div class="col-md-8">
        <span class="mat-title text-grey">Stuur 'n boodskap</span>
        <app-contact-form></app-contact-form>
    </div>
    <div class="col-md-4">


            <span class="mat-title text-grey">Kontak Besonderhede</span>
            <br/>
            <a href="tel:0113911343" class="mat-display-1 mb-0 text-dark">
              011 391 1343<br />
            </a>
            <a class="mat-title" href="mailto:admin@kruin-kerk.co.za">{{
                "admin@kruin-kerk.co.za"
              }}</a
              >
<br><br>


            <span class="mat-title text-grey">Nood Nommer</span>
            <p class="mat-title mb-0">Koster Anton Minnaar</p>
            <a href="tel:081 757 9783" class="mat-title mb-0 text-dark">
                081 757 9783<br />
              </a>
            <br>

            <span class="mat-title text-grey">Bankbesonderhede</span>
            <p class="mat-title">
              NG Kempton Kruin<br />
              Absa-bank Tjekrekening<br />
              Rekeningnr 260 580 965<br />
              Verwysing Dankoffer
            </p>


</div>
        <div class="col-md-12">

            <span class="mat-title text-grey">Kom kuier</span>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.691248919547!2d28.242030676966344!3d-26.076344977155653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9514d16d90b513%3A0x284e76e27397f15b!2sNG%20Kempton-%20Kruin!5e0!3m2!1sen!2sza!4v1730967149820!5m2!1sen!2sza" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </div>
</div>




</div>