import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ReadMoreDialogComponent } from '../shared/read-more-dialog/read-more-dialog.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  isAdmin = false;
  currentUser = '';
    groups = [
    {
      title: 'Kinderkerk',
      description: '',
      url: 'https://drive.google.com/file/d/1EnE0mjhb19x2YvLWt0r6rf2Oig7Yo3gc/preview',
      icon: '../../../assets/icon_kinders.png',
      video: 'https://drive.google.com/file/d/15WXWO_MK0CrQi10ufrkESz0ceuYEdYBA/preview'
    },{
      title: 'Jeugkerk',
      description: '',
      url: '',
      icon: '../../../assets/icon_jeug.png',
      video: 'https://www.youtube.com/embed/FLZ7b8dIDbg?si=oPrTNJmebplVVU5U'
    }];
  constructor(private authService: AuthService, private router: Router) { 
    authService.$User.subscribe((res)=>{
      if(res) {
        this.currentUser = res.get('Name');
        this.isAdmin = res.get('role') === 'admin';
      }
    })
  }
  ngOnInit(): void {
    if(this.currentUser !== '') {
      if(this.isAdmin){
        this.router.navigate(['admin', 'appointments']);
      } else {
        this.router.navigate(['member']);
      }
    }
  }

  newAppointment(): void{
    this.router.navigateByUrl('appointment');
  }

  docuMerge(): void{
    this.router.navigateByUrl('pdf-worker');
  }

  explore(): void{
    this.router.navigateByUrl('explore');
  }
  readonly dialog = inject(MatDialog);

  openDialog(group: any) {

    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: group,
      width: '800px',
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
