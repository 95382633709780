import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faFile, faFilePdf, faImage, faUser } from '@fortawesome/free-solid-svg-icons';
import { Student } from 'backend/interfaces/student.interface';
import { StudentService } from 'src/app/admin/services/student.service';
import { FileViewerDialogComponent } from '../file-viewer-dialog/file-viewer-dialog.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-letters',
  templateUrl: './letters.component.html',
  styleUrls: ['./letters.component.scss']
})
export class LettersComponent implements OnInit {

  constructor(private studentService: StudentService, private _snackBar: MatSnackBar, readonly dialog: MatDialog, private router: Router) { }

  loading = false;
  student: Student;
  files;
  selectedFile: {type: string, src: string};
  uploadedFile = {name: '', type: '', source: null};
  faImage = faImage;
  faFile = faFile;
  faUser = faUser
  async ngOnInit() {
    this.loading = true;
    this.studentService.activatedStudent$.subscribe(async s => {
      return this.student = s;
    });
    this.studentService.activatedDrive$.subscribe(async d => {
      this.files = d
    })
    this.loading = false
  }

  createDriveFolder(){
    this.loading = true;
    this.studentService.createDriveFolder(this.student.id).then(async result => {
      this.student.DriveId = result.DriveId;
      this.router.navigateByUrl('/', { skipLocationChange: true });
      this.loading = false;
    });
  }

  async uploadFile(){
    let description = '';
    this.loading = true;
    this.studentService.uploadFile(this.student.DriveId, this.uploadedFile, description).then(async res => {
      this.openSnackBar('Upload Successful!');
      await this.studentService.getstudentFiles(this.student.DriveId).then(res => {
        if(res.includes("not found"))
        {
          console.log(res)
        }else{
          this.files = res;
        }
      })
      this.loading = false;
    });
  }


async getFile(fileId: string, mimeType: string, fileName: string){
  this.loading = true;
  // document.getElementById('showcaseImage').setAttribute("src", '');
  // document.getElementById('showcaseFile').setAttribute("src", '');
  return this.studentService.getStudentFile(fileId, mimeType).then(res => {
    // if(mimeType.includes('image')){
    //   document.getElementById('showcaseImage').setAttribute("src", res);
    // }else{
      this.viewDriveFolder(fileName, mimeType, res, fileId)
      // document.getElementById('showcaseFile').setAttribute("src", res);
    // }
    this.selectedFile = {
      type: mimeType.includes('image') ? 'image' : 'file',
      src: res
    }
    this.loading = false;
    return res;
  }).catch(err => console.log(err))

}
  
  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
          reader.onload = () => {
              this.uploadedFile = {
                source: reader.result,
                type: file.type,
                name: file.name
              }
          };
          reader.readAsDataURL(file)
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
  async viewDriveFolder(name, mimeType, file, fileId){
    // if(mimeType.includes('image')){
    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
      data: {name, mimeType, file, fileId},
      width: '90%',
      maxWidth: '100vw',
      height: '90%',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
      } else {
        this.dialog.closeAll();
      }
    });
  // }else{
  //   var contentType = mimeType;
  //   // var dataBlob = this.b64toBlob(file, contentType);
  //   // Browser.open({url: URL.createObjectURL(dataBlob)});
  //   const authToken = await Parse.Cloud.run('getGToken', { });
    
  //     // return this.http.post(
  //     //       'https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate',
  //     //       { albumId: id, newMediaItems }, {headers: batchCreateHeaders}).toPromise();
  //   const options = {
  //     url: 'https://www.googleapis.com/drive/v3/files/'+fileId,
  //     headers: {Authorization: 'Bearer '+ authToken}
  //   };
  
  //   const response: HttpResponse = await CapacitorHttp.get(options);
  //   const fileOpenerOptions: FileOpenerOptions = {
  //     filePath: response.url,
  //     contentType: contentType,
  //   }

  //   await FileOpener.open(fileOpenerOptions)
  //     .then(() => {
  //       console.log('file is open')
  //       // if (!file?.url) return

  //       // try {
  //       //   this._downloadAndOpenFiles(file)
  //       // } catch (error) {

  //         Browser.open({ url: file.path })
  //       // }
  //     })
  //     .catch((error) => {
  //       console.error(error)
  //     })

  }
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    
      //decode base64 data
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
    
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
    
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
    
          var byteArray = new Uint8Array(byteNumbers);
    
          byteArrays.push(byteArray);
      }
    
      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }
  
}
