<div class="p-3 sidebar-container h-100">
    <!-- <div class="bg-image">
      <img src="./../../../assets/auth_bg.png"/>
    </div> -->
<div class="banner-title bg-secondary text-white mat-title pl-4 mb-3"><a class="text-white link" target="_blank" (click)="viewCalendarGallery()">KALENDER</a></div>
    <mat-card>
      <div class="d-flex pt-2 pb-2 align-items-center">
        <h2 class="m-0">Eredienste</h2>
      </div>
      <mat-card-content>
        <div *ngFor="let item of eredienste" class="d-flex justify-content-between mt-2 mb-2">
          <span>{{item.title}}</span>
          <span>{{item.time}}</span>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-around mt-3">
      <div *ngFor="let group of groups" (click)="openDialog(group)" class="link">
                        <img class="img-fluid icon" [src]="group.icon">
      </div>
      </div>
<div class="banner-title bg-primary text-white mat-title pl-4 mb-3 mt-3">KOM KUIER</div>
<mat-card class="m-0 relative p-2 mt-3 mb-3">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.691248919547!2d28.242030676966344!3d-26.076344977155653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9514d16d90b513%3A0x284e76e27397f15b!2sNG%20Kempton-%20Kruin!5e0!3m2!1sen!2sza!4v1730967149820!5m2!1sen!2sza" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</mat-card>
    <h3 class="text-white">Skandeer QR KODES vir meer inligting</h3>
    <mat-card class="m-0 relative p-2">
      <div class="row mb-3">
        <div class="col-md-5 d-flex flex-column justify-content-center">
          <a href="https://chat.whatsapp.com/KcDSkfmQJ03COoE8ES1cFx" target="_blank">
            <img class="img-fluid" src="../../../assets/KruinWhatsappGroupQR.png"/>
          </a>
        </div>
        <div class="col-md-7">
          <h3 class="m-0">WELKOM</h3>
          <h3 class="m-0">NUWE INTREKKERS</h3>
          <p>Ons Leraars
          wil jou graag ontmoet!
          </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-5 d-flex flex-column justify-content-center">
          <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScnCXIl7TIe709MYYSTuaCh_y8CSLpXYe2xjht_zrg1UJAjrg/viewform?usp=sf_link">
          <img class="img-fluid" src="../../../assets/BetrokkeRaakQR.png"/></a>
        </div>
        <div class="col-md-7">
          <h3 class="m-0">WAAR KAN JY BETROKKE RAAK</h3>
          <p>Bedieninge, Belangegroepe,
            Groeigroepe,
            Lewensgroeigroepe
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 d-flex flex-column justify-content-center">
          <a target="_blank" href="">
            <img class="img-fluid" src="../../../assets/SnapscanQR.png"/>
          </a>
        </div>
        <div class="col-md-7">
          <h3 class="m-0">GEE OMDAT JY OMGEE</h3>
          <p>NG Kempton Kruin<br>
            Absa-bank tjekrekening<br>
            Rek.nr 260 580 965<br>
            Verwysing dankoffer
          </p>
        </div>
      </div>
    </mat-card>
    <mat-card class="m-0 relative p-2 mt-3" *ngIf="votd">
      <a href="https://www.bible.com/af/verse-of-the-day" target="_blank">
      <div class="d-flex pt-2 pb-2 align-items-center">
        <h2 class="m-0">Vers vir die dag</h2>
      </div>
      <div class="d-flex pt-2 pb-2 align-items-center">
        <fa-icon [icon]="faBook" class="mr-2"></fa-icon>
        <h3 class="m-0">{{votd.citation}}</h3>
      </div>
      <p>{{votd.passage}}</p>
      </a>
    </mat-card>
    <!-- <div class="m-3 relative text-white">
      <div class="d-flex pt-2 pb-2 align-items-center">
        <fa-icon size="2x" [icon]="faCalendar" class="mr-2"></fa-icon>
        <h2 class="m-0 text-white">Gebeure</h2>
      </div>
      <div *ngFor="let item of gebeure" class="d-flex justify-content-between flex-column mt-2 mb-2">
        <span>{{item.start | date}}</span>
        <h3>{{item.meta.appointmentType.summary}}</h3>
      </div>
    </div> -->
    <hr class="text-white relative bg-white">
    <div class="m-3 relative text-white">
      <div class="d-flex pt-2 pb-2 align-items-center justify-content-between flex-wrap w-100">
        <a href="https://www.facebook.com/www.kruinkerk.co.za" target="_blank" class="initials p-1"><img src="./../../../assets/icons8-facebook.svg"/></a>
        <a href="https://twitter.com/KruinKerk" target="_blank" class="initials p-1"><img src="./../../../assets/icons8-twitterx.svg"/></a>
        <a href="https://www.instagram.com/kruin_kerk/" target="_blank" class="initials p-1"><img src="./../../../assets/icons8-instagram.svg"/></a>
        <a href="https://www.youtube.com/@kruinkerk786" target="_blank" class="initials p-1"><img src="./../../../assets/icons8-youtube-48.svg"/></a>
        <a href="" target="_blank" class="initials p-1"><img src="./../../../assets/icons8-whatsapp.svg"/></a>
      </div>
    </div>
  </div>